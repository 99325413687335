<template>
  <div class="mapa-container">
    <img src="../assets/mapa-san-andrea-priu.png" alt="">
    <div class="points">
      <div class="point" @click="openViewer(1)"></div>
      <div class="point" @click="openViewer(2)"></div>
      <div class="point" @click="openViewer(3)"></div>
      <div class="point" @click="openViewer(4)"></div>
      <div class="point" @click="openViewer(5)"></div>
      <div class="point" @click="openViewer(6)"></div>
      <div class="point" @click="openViewer(7)"></div>
      <div class="point" @click="openViewer(8)"></div>
      <div class="point" @click="openViewer(9)"></div>
      <div class="point" @click="openViewer(10)"></div>
      <div class="point" @click="openViewer(11)"></div>
    </div>
    <div v-show="showViewer" id="viewer" @keydown.esc="closeViewer()"></div>
    <div v-if="showViewer" class="close" @click="closeViewer()"></div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import OpenSeaDragon from 'openseadragon'

const showViewer = ref(false)
let viewer = null
const openViewer = (id) => {
  showViewer.value = true
  viewer = OpenSeaDragon({
    id: "viewer",
    tileSources: `/dzi/${id}/${id}.dzi`
  })
  document.querySelector('#viewer').focus()
}

function closeViewer() {
  viewer.destroy()
  showViewer.value = false
}

</script>
<style>
.mapa-container {
  max-width: 1420px;
  margin: 0 auto;
  position: relative;
}

.mapa-container img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  top: 0;
}

.points {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.point {
  position: absolute;
  width: min(2vw, 35px);
  height: min(2vw, 35px);
  background-color: rgb(211, 81, 81);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  background-image: url('../assets/lupa4.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.point:hover {
  transform: scale(1.2);
  box-shadow: 0px 3px 4px #00000030;
}

.point:nth-of-type(1) {
  top: 20%;
  left: 33%;
}

.point:nth-of-type(2) {
  top: 18%;
  left: 36.5%;
}

.point:nth-of-type(3) {
  top: 22%;
  left: 41%;
}

.point:nth-of-type(4) {
  top: 28.5%;
  left: 45%;
}

.point:nth-of-type(5) {
  top: 31.5%;
  left: 46.5%;
}

.point:nth-of-type(6) {
  top: 35.5%;
  left: 45%;
}

.point:nth-of-type(7) {
  top: 34%;
  left: 40%;
}

.point:nth-of-type(8) {
  top: 30.5%;
  left: 37.5%;
}

.point:nth-of-type(9) {
  top: 26.5%;
  left: 34.5%;
}

.point:nth-of-type(10) {
  top: 28.5%;
  left: 30%;
}

.point:nth-of-type(11) {
  top: 38%;
  left: 39%;
}

#viewer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000CC;
  top: 0;
  left: 0;
}

.close {
  z-index: 10;
  position: fixed;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  background-color: #000000AA;
  background-image: url('../assets/close.png');
  cursor: pointer;
}

.close:hover {
  background-color: black;
}
</style>